import React from 'react'
import { useState, useEffect } from 'react';
import imgFondo from '../Images/fondo_blanco.jpg'
import logoFinanciera from '../Images/Logo_financiera_casabonita.jpg'
import visitaWeb from '../Images/visitaWeb.jpg'
import trash from '../Images/trash.png'
import GlobalFunctions from '../services/GlobalFunctions'
import FinancieraService from '../services/FinancieraService'
import DialogoRespuestaSolicitud from './GeneralUI/DialogoRespuestaSolicitud'
import { useParams } from 'react-router-dom'
import Cargando from './GeneralUI/Cargando';
import GeneralMenu from './GeneralUI/GeneralMenu';
import Swal from 'sweetalert2'

const Requests = (params) => {
  const glob = new GlobalFunctions()
  const service = new FinancieraService()
  const [telefono, setTelefono] = useState('')
  const [mensaje, setMensaje] = useState('Los campos con * son obligatorios')
  const [datosPersonales, setDatosPersonales] = useState({
    nombre: '',
    cedula: '',
    direccion: '',
    prestamo: 0,
    periodicidad: 'Semanal',
    telefonos: [],
    otros: '',
    sugerencias: '',
    valorPrestamoAnterior: 0
  })
  const [datosDialogo, setDatosDialogo] = useState({
    mensaje: '',
    imagen: '',
    saldo: 0
  })
  const { id, user, phrase, ref, tt, secreto } = useParams()

  useEffect(() => {
    if (id !== 'Im') {
      glob.setCookie('usuario', user, glob.setExpires('1'))
      glob.setCookie('cedula', id, glob.setExpires('1'))
      glob.setCookie('clave', phrase, glob.setExpires('1'))
      document.getElementById('form_wompi').submit()
      return
    }
  })

  function sweetAlert(mensaje) {
    Swal.fire({
      title: mensaje,
      icon: 'warning',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true
    })
  }

  function getDatosCliente() {
    if (datosPersonales.cedula !== '') {
      service.getDatosParaFormulario(datosPersonales.cedula).then((data) => {
        const tels = data.tel_fijo.split("//")
        if (tels.length > 0) {
          if (tels[0] == '' || tels[0] == null) {
            tels.shift()
          }
        }
        setDatosPersonales((valores) => ({
          ...valores,
          nombre: data.nombre,
          direccion: data.direccion,
          prestamo: data.valorprestamo,
          periodicidad: data.periodicidad,
          telefonos: tels,
          valorPrestamoAnterior: data.valorprestamo
        }))
      })
    }
  }

  function cambioCedula(event) {
    setDatosPersonales((valores) => ({
      ...valores,
      cedula: event.target.value
    }))
  }

  function cambioNombre(event) {
    setDatosPersonales((valores) => ({
      ...valores,
      nombre: event.target.value
    }))
  }

  function cambioDireccion(event) {
    setDatosPersonales((valores) => ({
      ...valores,
      direccion: event.target.value
    }))
  }

  function cambioPrestamo(event) {
    setDatosPersonales((valores) => ({
      ...valores,
      prestamo: event.target.value
    }))
  }

  function cambioPeriodicidad(event) {
    setDatosPersonales((valores) => ({
      ...valores,
      periodicidad: event.target.value
    }))
  }

  function cambioOtros(event) {
    setDatosPersonales((valores) => ({
      ...valores,
      otros: event.target.value
    }))
  }

  function cambioSugerencias(event) {
    setDatosPersonales((valores) => ({
      ...valores,
      sugerencias: event.target.value
    }))
  }

  function borrarTelefono(tel) {
    const temp = datosPersonales.telefonos.filter((art) => art !== tel);
    setDatosPersonales((valores) => ({
      ...valores,
      telefonos: temp
    }))
  }

  function cambioTelefono(event) {
    setTelefono(event.target.value)
  }

  function agregarTelefono() {
    if (telefono !== '') {
      let tels = datosPersonales.telefonos
      tels.push(telefono)
      setDatosPersonales((valores) => ({
        ...valores,
        telefonos: tels
      }))
      setTelefono('')
    }
  }

  function loadingOn() {
    document.getElementById('btnLoading').style.display = 'inline'
    document.getElementById('btnEnviar').style.display = 'none'
  }

  function loadingOff() {
    document.getElementById('btnLoading').style.display = 'none'
    document.getElementById('btnEnviar').style.display = 'inline'
  }

  function enviarFormulario() {
    if (validarCamposVacios() == 5) {
      loadingOn()
      service.enviarFormulario(datosPersonales).then((data) => {
        loadingOff()
        if (data.respuesta === 'Ingresado') {
          cargarRespuesta(data, datosPersonales.prestamo)
        } else {
          alert('Ha ocurrido un error en el servidor!')
        }
      })
    }

  }

  function cargarRespuesta(data, prest) {
    if (data.saldo > 0) {
      setDatosDialogo({
        mensaje: 'Estimado cliente, hemos detectado que tienes saldo pendiente, sin embargo procesaremos tu solicitud y pronto nos comunicaremos contigo. Si deseas puedes contactarte con uno de nuestros asesores.',
        imagen: process.env.REACT_APP_URL_DATOS_PARA_FORMULARIO + '/Imagenes/phone_ringing.gif',
        saldo: data.saldo
      })
    } else {
      if (data.aprobacion === 'true') {
        setDatosDialogo({
          mensaje: "Tu prestamo por $ " + prest + " pesos ha sido aprobado. Muy pronto uno de nuestros asesores lo hará efectivo.",
          imagen: process.env.REACT_APP_URL_DATOS_PARA_FORMULARIO + '/Imagenes/pulgar_arriba.gif',
          saldo: 0
        })
      } else {
        setDatosDialogo({
          mensaje: "Tu solicitud se ha realizado satisfactoriamente. Muy pronto uno de nuestro asesores se comunicará contigo.",
          imagen: process.env.REACT_APP_URL_DATOS_PARA_FORMULARIO + '/Imagenes/phone_ringing.gif',
          saldo: 0
        })
      }
    }
    setTimeout(() => {
      document.getElementById('btnRespuesta').click()
    }, 100);

  }

  function validarCamposVacios() {
    let lleno = 0
    if (datosPersonales.cedula === '') {
      setMensaje('Ingresa tu número de cédula!')
      sweetAlert('Ingresa tu número de cédula!')
    } else {
      lleno++
    }
    if (datosPersonales.nombre === '') {
      setMensaje('Ingresa tu nombre!')
      sweetAlert('Ingresa tu nombre!')
    } else {
      lleno++
    }
    if (datosPersonales.direccion === '') {
      setMensaje('Ingresa tu dirección!')
      sweetAlert('Ingresa tu dirección!')
    } else {
      lleno++
    }
    if (datosPersonales.prestamo === '') {
      setMensaje('Ingresa un valor de crédito!')
      sweetAlert('Ingresa un valor de crédito!')
    } else {
      lleno++
    }
    if (datosPersonales.telefonos.length < 1) {
      setMensaje('Debes ingresar al menos un télefono!')
      sweetAlert('Debes ingresar al menos un télefono!')
    } else {
      lleno++
    }
    setTimeout(() => {
      setMensaje('Los campos con * son obligatorios.')
    }, 5000);
    return lleno
  }

  function goHome() {
    document.getElementById('btnGoHome').click()
    document.getElementById('btnRespuesta').click()
  }

  function goContact() {
    document.getElementById('btnGoContact').click()
    document.getElementById('btnRespuesta').click()
  }

  function goCasabonita() {
    let href = process.env.REACT_APP_URL_CASA
    window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
  }

  return (
    <div>
      <div style={{ display: id !== 'Im' ? 'inline' : 'none' }}>
        <Cargando></Cargando>
      </div>
      <div style={{ display: 'none' }}>
        <form id="form_wompi" action="https://checkout.wompi.co/p/" method="GET">
          <input type="hidden" name="public-key" value="pub_prod_mm5Qq0EJtZhzNzjV4Vm6fLQx6aHhCbjS" />
          <input type="hidden" name="currency" value="COP" />
          <input type="hidden" id="amount" name="amount-in-cents" value={tt} />
          <input type="hidden" id="ref" name="reference" value={ref} />
          <input type="hidden" id='integrity' name="signature:integrity" value={secreto} />
          <input type="hidden" id='redirect' name="redirect-url" value="https://financiera.tucasabonita.site/financiera/" />
          {/*Pongo estos porque wompi me los pide y necesito line 1 para saber que es pago financiera, credito casa bonita.*/}
          <input type="hidden" id='direccion' name="shipping-address:address-line-1" value="pagofinanciera" />
          <input type="hidden" name="shipping-address:country" value="CO" />
          <input type="hidden" id='cliente' name="shipping-address:phone-number" value={id} />
          <input type="hidden" id='nCredito' name="shipping-address:city" value="noaplica" />
          <input type="hidden" name="shipping-address:region" value='Santander' />
        </form>
      </div>
      <br></br>
      <GeneralMenu goHome={params.goHome} validarGoMiCuenta={params.validarGoMiCuenta} goSolicitudes={params.goSolicitudes}></GeneralMenu>
      <h1 style={{ color: 'black', textAlign: 'center' }}>Solicitud de credito N° 2. Ya soy cliente.</h1>
      <div style={{ display: id !== 'Im' ? 'none' : '' }} className='container border rounded'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
            <p style={{ marginTop: '0.5em' }}>Agradecemos actualices tu información. La usaremos para contactanos contigo.</p>
            <p style={{ textAlign: 'left', color: 'red' }}>{mensaje}</p>
            <p id="ingresaCedula" style={{ textAlign: 'justify', color: 'black' }}>Ingresa tu número de cédula.<strong style={{ color: 'red' }}>*</strong></p>
            <input onChange={cambioCedula} onBlur={getDatosCliente} defaultValue={datosPersonales.cedula ? datosPersonales.cedula : ''} type="number" className="form-control" placeholder="Número de cedula" />
            <br></br>
            <span style={{ textAlign: 'left', color: 'red' }}><strong>*</strong></span>
            <input type="text" onChange={cambioNombre} defaultValue={datosPersonales.nombre ? datosPersonales.nombre : ''} className="form-control" placeholder="Nombre" />
            <br></br>
            <span style={{ textAlign: 'left', color: 'red' }}><strong>*</strong></span>
            <textarea onChange={cambioDireccion} defaultValue={datosPersonales.direccion ? datosPersonales.direccion : ''} rows="2" className="form-control" placeholder="Direccion domicilio"></textarea>
            <br></br>
            <p style={{ textAlign: 'left', color: 'black' }}>Télefonos <span style={{ textAlign: 'left', color: 'red' }}><strong>*</strong></span></p>
            {/* div telefonos */}
            <div style={{ textAlign: 'center' }} className="container">
              <div className="row justify-content-center" >
                {datosPersonales.telefonos.map((item, index) => {
                  return (
                    <div key={index} style={{ margin: '1em' }} className="col-lg-4 col-md-4 col-sm-4 col-4 border">
                      <p>{item}</p>
                      <img onClick={() => borrarTelefono(item)} style={{ marginBottom: '0.4em', height: '1.24m', width: '1.4em', cursor: 'pointer', backgroundColor: 'red', padding: '0.1em' }} src={trash} />
                    </div>
                  )
                })}
              </div>
              <div className="row">
                <div className="col-sm-8 col-8">
                  <input onChange={cambioTelefono} onBlur={agregarTelefono} value={telefono} type="number" className="form-control" placeholder="Nuevo número télefono" />
                </div>
                <div className="col-sm-4 col-4">
                  <a type="button" className="btn btn-success">Agregar</a>
                </div>
              </div>
            </div>
            {/* fin div telefonos */}
            <br></br>
            <p style={{ textAlign: 'justify', color: 'black' }}>Otros:
              <br></br>
              Cuéntanos si haz modificado algún otro de tus datos, estaremos muy agradecidos.</p>
            <textarea rows="2" onChange={cambioOtros} className="form-control" placeholder="Ejemplo: direccion de trabajo... telefono trabajo...Información de referencias...."></textarea>
            <br></br>
            <p style={{ textAlign: 'justify', color: 'black' }}>Sugerencias:
              <br></br>
              Queremos mejorar. Déjanos tu sugerencia. Con tu ayuda prestaremos un mejor servicio. Gracias.</p>
            <textarea rows="2" onChange={cambioSugerencias} className="form-control" placeholder="Sugerencias... Comentarios... Inquietudes..."></textarea>
            <br></br>
            <p style={{ textAlign: 'justify', color: 'black' }}>Valor de crédito a solicitar.<strong style={{ color: 'red' }}>*</strong></p>
            <input type="number" onChange={cambioPrestamo} defaultValue={datosPersonales.prestamo ? datosPersonales.prestamo : ''} className="form-control" />
            <br></br>
            <p style={{ textAlign: 'justify', color: 'black' }}>Periodicidad .<strong style={{ color: 'red' }}>*</strong>
              <br></br>
              Selecciona cada cuanto puedes realizar abonos, es importante para calcular el valor de las cuotas.</p>
            <select value={datosPersonales.periodicidad} onChange={cambioPeriodicidad} className="form-select">
              <option value="diaria">Diaria</option>
              <option value="semanal">Semanal</option>
              <option value="quincenal">Quincenal</option>
              <option value="mensual">Mensual</option>
            </select>
            <br></br>
            <div style={{ textAlign: 'center' }}>
              <button id='btnEnviar' type="button" onClick={enviarFormulario} className="btn btn-success">Enviar solicitud</button>
              <button id='btnLoading' type="button" disabled style={{ backgroundColor: 'gray', display: 'none' }} className="btn btn-primary" >
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="sr-only">Loading...</span>
              </button>
            </div>
            <br></br>
            <hr />
          </div>

          <div style={{ textAlign: 'center' }} className='col-lg-6 col-md-6 col-sm-12 col-12'>
            <br></br><br></br>
            <img height="260" width="260" style={{ cursor: 'pointer', border: 'border rounded', margin: 'auto', display: 'block' }} src={visitaWeb}></img>
            <br></br>
            <a type="button" onClick={goCasabonita} className="btn btn-primary">Ir a tucasabonita.site!</a>
            <br></br><br></br><br></br><br></br>
            <p >Recuerda que todos los productos de tu casa bonita también los puedes pagar a crédito!</p>
            <br></br><br></br><br></br>
          </div>
        </div>
      </div>
      <br></br>
      <button style={{ display: 'none' }} id="btnRespuesta" type="button" data-toggle="modal" data-target="#exampleModal"></button>
      <DialogoRespuestaSolicitud goHome={goHome} goContact={goContact} datosDialogo={datosDialogo} />
      <button style={{ display: 'none' }} id="btnGoHome" onClick={params.goHome}></button>
      <button style={{ display: 'none' }} id="btnGoContact" onClick={params.goContact}></button>
    </div>
  )
}

export default Requests